import axios from "axios"

export const getItineraries = async (rallyId: number) => {
    let itineraries: Itinerary[]
    const url = `${process.env.REACT_APP_API_BASE_URL}/timing/api/itineraries/${rallyId}.json`

    try {
        const response = await axios.get(url)

        itineraries = response.data.event.data

        if (!itineraries) {
            return []
        }

        return itineraries
    } catch (error) {
        return []
    }
}