'use client'

import { PropsWithChildren } from 'react'
import { ConfigProvider, theme } from 'antd'
import { useTranslation } from 'react-i18next'
import { languages, defaultLocale } from '../locale'

type AntdConfigProviderProps = PropsWithChildren<{ locale: string }>

const AntdConfigProvider = ({ children, locale }: AntdConfigProviderProps) => {
    const isDarkTheme = false

    // Get the theme to set on antd
    const getAntdTheme = () => {
        return theme.defaultAlgorithm
    }

    return (
        <ConfigProvider
            locale={(languages as any)[(locale as any) ?? defaultLocale].antd}
            theme={{
                algorithm: getAntdTheme(),
                token: {
                    colorPrimary: '#e10818',
                    colorBgElevated: isDarkTheme ? '#171717' : '#f5f5f5',
                },
                components: {
                    Button: {
                        defaultBg: 'rgba(255, 255, 255, 0)',
                        defaultBorderColor: 'rgba(255, 255, 255, 0)',
                        defaultShadow: 'false',
                    },
                    Carousel: {
                        colorBgContainer: '#e10818',
                        controlHeightSM: -29,
                    },
                    Progress: {
                        lineBorderRadius: 10,
                        defaultColor: '#18181b',
                        marginXS: 0,
                        lineHeight: 0,
                    },

                    Select: {},
                },
            }}
        >
            {children}
        </ConfigProvider>
    )
}
type ThemeProviderProps = PropsWithChildren<{}>
export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    var { i18n } = useTranslation()

    return <AntdConfigProvider children={children} locale={i18n.language} />
}
