import axios from 'axios'

const cacheTTL = 5 * 1000
const cachedSpecials: any = {}

export const getSpecials = async (rallyId: number, itineraryId: number) => {

    var specials: Special[] = []
    const url = `${process.env.REACT_APP_API_BASE_URL}/timing/api/specials/${rallyId}.json?itinerary_id=${itineraryId}`

    if (cachedSpecials[url] && new Date().getTime() - cachedSpecials[url].timestamp < cacheTTL) {
        return cachedSpecials[url].data
    }

    try {
        const response = await axios.get(url)

        specials = response.data.event.data

        if (specials) {

            cachedSpecials[url] = {
                data: specials,
                timestamp: new Date(),
            }

            return Array.from(specials)

        } else {
            return []
        }

    } catch (error) {
        return []
    }
}
