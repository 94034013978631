import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

const AppContext = createContext<AppContext | undefined>(undefined)

type AppProviderProps = {
    children: ReactNode
    defaultAttribute: string
    allowAllInAttributes: boolean
}

const AppProvider = ({ children, defaultAttribute, allowAllInAttributes }: AppProviderProps) => {
    const initialAttributeFilter = () => {
        if (!allowAllInAttributes && !defaultAttribute) {
            return attributes[0]?.value
        }

        if (defaultAttribute) {
            return defaultAttribute
        }

        return 'all'
    }

    const [showFilters, setShowFilters] = useState<boolean>(true)

    const [selectedAttributesFilter, setSelectedAttributesFilter] = useState<string>(initialAttributeFilter)

    let initialGroupFilter: GroupFilter = {
        key: 'all',
        value: 'All',
    }

    const [isAttributeAllSelected, setIsAttributeAllSelected] = useState(false)
    const [attributes, setAttributes] = useState<AttributeFilter[]>([])
    const [defaultAttributeOption, setDefaultAttributeOption] = useState<AttributeFilter>({
        value: '',
        label: '',
    })

    const [selectedGroupFilter, setSelectedGroupFilter] = useState<GroupFilter>(initialGroupFilter)

    useEffect(() => {
        setIsAttributeAllSelected(selectedAttributesFilter === 'all')
    }, [selectedAttributesFilter])

    const clearFilters = () => {
        setSelectedGroupFilter({
            key: 'all',
            value: 'All',
        })

        setSelectedAttributesFilter('all')
    }

    const handleFilterGroupChange = (newValue: { value: string; label: string }) => {
        const formattedOption = {
            key: newValue.value,
            value: newValue.label,
        }
        setSelectedGroupFilter(formattedOption)
    }

    const handleFilterAttributesChange = (newValue: AttributeFilter) => {
        if (newValue) {
            setSelectedAttributesFilter(newValue.value)
        }
    }

    useEffect(() => {
        const setNewAttribute = (attributes: AttributeFilter[], defaultAttribute: string) => {
            if (defaultAttribute) {
                const newAttribute = {
                    value: defaultAttribute,
                    label: defaultAttribute,
                }
                const newDefaultValue = attributes.find(
                    (option: AttributeFilter) => option.value === newAttribute.value
                )

                if (newDefaultValue) {
                    setDefaultAttributeOption(newDefaultValue)
                    handleFilterAttributesChange(newAttribute)
                } else {
                    //All
                    setDefaultAttributeOption(attributes[0])
                }
            }
        }

        if (showFilters && isAttributeAllSelected) {
            setNewAttribute(attributes, defaultAttribute)
        }
    }, [showFilters, attributes, allowAllInAttributes, isAttributeAllSelected, defaultAttribute])

    useEffect(() => {
        if (!showFilters) {
            clearFilters()
        }
    }, [showFilters])

    return (
        <AppContext.Provider
            value={{
                selectedGroupFilter,
                handleFilterGroupChange,

                attributes,
                setAttributes,
                selectedAttributesFilter,
                setSelectedAttributesFilter,
                handleFilterAttributesChange,

                defaultAttributeOption,
                setDefaultAttributeOption,

                allowAllInAttributes,

                showFilters,
                setShowFilters,
                clearFilters,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

const useAppProvider = (): AppContext => {
    const context = useContext(AppContext)
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider')
    }
    return context
}

export { AppProvider, useAppProvider }
