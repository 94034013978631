import axios from 'axios'

export const getRaceWithSubrallies = async (rallyId: number): Promise<Race> => {
    const url: string = `${process.env.REACT_APP_API_BASE_URL}/timing/api/race/${rallyId}.json?add_subrallies_races=1`

    const response = await axios.get(url)

    var race: Race = response.data.event.data

    return race
}


