import { Locale } from 'antd/lib/locale'
import en_US from 'antd/locale/en_US'
import es_ES from 'antd/locale/es_ES'
import pt_PT from 'antd/locale/pt_PT'

export const languages = {
	'en': {
		name: 'English',
		flag: '🇺🇸',
		unicode: '1f1fa-1f1f8',
		antd: en_US
	},
	'es': {
		name: 'Español',
		flag: '🇪🇸',
		unicode: '1f1fa-1f1f8',
		antd: es_ES
	},
	'pt': {
		name: 'Português',
		flag: '🇵🇹',
		unicode: '1f1fa-1f1f8',
		antd: pt_PT
	},
}

export type ILanguage = {
	[K in keyof typeof languages]: {
		name: string
		flag: string
		unicode: string
		antd: Locale
	}
}

export const defaultLocale: keyof typeof languages = 'en'
